<div class="backdrop"></div>

@if (currentPositionAndSizeData && currentPositionAndSizeData()) {
  <div
    [cdkDragFreeDragPosition]="{
      x: currentPositionAndSizeData().x,
      y: currentPositionAndSizeData().y,
    }"
    [elementResizeOptions]="elementResizeOptions"
    (cdkDragStarted)="onDragStarted()"
    (cdkDragEnded)="onDragEnded($event)"
    (onResizing)="onResizing($event)"
    (onResizeStart)="onResizeStart()"
    (onResizeEnd)="onResizeEnd()"
    class="wrapper"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".pxo-app"
    appElementResizable
    #drag="cdkDrag"
  >
    <div
      [style.width]="currentPositionAndSizeData().height * elementResizeOptions.aspectRatio + 'px'"
      #topMenu
      class="top-menu"
    >
      <div
        [style.visibility]="videoMetadata()?.video ? 'visible' : 'hidden'"
        class="move-section"
        cdkDragHandle
      >
        <mat-icon [svgIcon]="'drag-handler'"></mat-icon>
      </div>
      <div
        class="video-title"
        cdkDragHandle
      >
        <span [innerHTML]="videoMetadata()?.video?.title | sanitizeHtml"></span>
      </div>
      <button
        [matTooltip]="'Open in the Video Hub'"
        [style.visibility]="videoMetadata()?.video ? 'visible' : 'hidden'"
        (click)="expandVideo()"
        mat-icon-button
        class="expand"
      >
        <mat-icon [svgIcon]="'expand-video-icon'"></mat-icon>
      </button>
      <button
        [matTooltip]="'Open in separate window'"
        [style.visibility]="videoMetadata()?.video ? 'visible' : 'hidden'"
        (click)="openInSeparateWindow()"
        mat-icon-button
        class="separate"
      >
        <mat-icon [svgIcon]="'separate-video-icon'"></mat-icon>
      </button>
      <button
        (click)="close()"
        mat-icon-button
        class="close"
      >
        <mat-icon [svgIcon]="'close'"></mat-icon>
      </button>
    </div>

    @if (videoMetadata()?.video) {
      <div
        [style.height]="currentPositionAndSizeData().height + 'px'"
        class="player resize"
      >
        @if (isMaskVisible()) {
          <div class="mask"></div>
        }

        @if (!isVideoLoaded()) {
          <!--Static Skeleton (Video Preloader) -->
          <app-video-player-skeleton class="video-player-skeleton"></app-video-player-skeleton>
        }

        @if (isNeededShowPlaceholder()) {
          <app-video-player-placeholder
            (click)="onPlayVideoAgain()"
            class="video-player-placeholder"
          ></app-video-player-placeholder>
        }

        @if (videoMetadata().video.source == 'youtube') {
          <app-youtube-player
            [videoId]="videoMetadata().video.videoId"
            [videoStartTime]="videoMetadata().timestamp ?? 0"
            (videoEnd)="onVideoEnd()"
            (videoLoaded)="onVideoLoaded()"
            #player
          ></app-youtube-player>
        } @else {
          <app-vimeo-player
            [videoUrl]="videoMetadata().video.url"
            [videoStartTime]="videoMetadata().timestamp ?? 0"
            (videoEnd)="onVideoEnd()"
            (videoLoaded)="onVideoLoaded()"
            #player
          ></app-vimeo-player>
        }
      </div>
    } @else {
      <div
        [style.height]="currentPositionAndSizeData().height + 'px'"
        class="loader-wrapper"
      >
        <mat-spinner
          class="blue-spinner"
          diameter="40"
        ></mat-spinner>
      </div>
    }
  </div>
}
