import { defaultEncryptEditionShift } from '@constants/video-hub.contants';
import { VideoModel } from '@mod/video-hub/video-hub.model';
import moment from 'moment';

export function getVideoPlaylistId(video: VideoModel): number {
  const length = video.categories.length;
  switch (length) {
    case 0:
      return null;
    case 1:
      return video.categories[0].id;
    default: {
      const subCategory = video.categories.filter((category) => category.parent > 0);
      if (!subCategory.length) {
        return video.categories[0].id;
      }
      return subCategory[0].id;
    }
  }
}

export function convertDurationToString(value: string | number): string {
  if (!value) return '';

  const zeroHoursRegExp = new RegExp(/^(00|0):[0-5][0-5]:[0-5][0-9]$/gm);

  if (typeof value === 'string' && zeroHoursRegExp.test(value)) {
    return value.startsWith('00') ? value.slice(3) : value.slice(2);
  }

  if (!value.toString().startsWith('P') && typeof value !== 'number') {
    return value == '00:00:00' ? '' : value;
  }

  const duration = typeof value === 'number' ? moment.duration(`PT${value}S`) : moment.duration(value.toString());

  if (!duration.hours() && !duration.minutes() && !duration.seconds()) {
    return '';
  }

  const hours = duration.hours() > 0 ? duration.hours().toString() + ':' : '';

  const minutes = duration.minutes().toString().padStart(2, '0');

  return `${hours}${minutes}:${
    duration.seconds().toString().length === 1 ? duration.seconds().toString().padStart(2, '0') : duration.seconds()
  }`;
}

export const convertDurationToSeconds = (value: string | number): number => {
  if (typeof value === 'number') {
    return value;
  }

  return moment.duration(value).asSeconds();
};

export function isValueInstanceOfViewType(value: string): boolean {
  return value === 'list-view' || value === 'cards-view';
}

export function сaesarEncypt(value: string, shift: number = defaultEncryptEditionShift): string {
  return Array.from(value)
    .map((char) => String.fromCharCode(char.charCodeAt(0) + shift))
    .join('');
}
